<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/order' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            v-if="order.orderStatus == '0'"
                            type="primary"
                            @click="dialogFormVisible = true"
                            >点击发货</el-button
                          >
                          <el-button
                            v-else-if="order.orderStatus == '3'"
                            type="primary"
                            @click="refund"
                            >确认退款</el-button
                          >
                          <!-- <el-button v-else type="primary" @click="">确认</el-button> -->

                          <!-- <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('order')"
                            >保存</el-button
                          > -->
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <el-table
                :data="tableDataItem"
                border
                fit
                
                highlight-current-row
                style="width: 100%"
               
              >
                <el-table-column label="序号" align="center" prop="id">
                  <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品图片"
                  align="center"
                  prop="订单编码"
                >
                  <template slot-scope="scope">
                    <el-image
                      :src="scope.row.logo"
                      style="width: 30%; height: 30%"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="订单编码"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.productName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="数量" align="center" prop="订单编码">
                  <template slot-scope="scope">
                    <span>{{ scope.row.num }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="付款金額"
                  align="center"
                  prop="订单编码"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.price * scope.row.num }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="totalprice">
                <div class="f14" style="padding: 10px">
                  商品总价（不含运费）:{{ chosenMoney || "0.00" }}
                </div>
                <div class="f14" style="padding: 0px 10px">
                  实付款:{{ order.orderAmount }}
                </div>
              </div>
              <div class="m15">订单号:{{ order.orderNumber }}</div>
              <div class="m15">
                下单时间:{{ order.createTime }}
              </div>
              <div class="m15">发货时间:{{ order.courierTime }}</div>
              <div class="m15">
                订单状态:{{
                  order.orderStatus == "0"
                    ? "未发货"
                    : order.orderStatus == "1"
                    ? "已发货"
                    : order.orderStatus == "2"
                    ? "已完成"
                    : order.orderStatus == "3"
                    ? "退款中"
                    : order.orderStatus == "4"
                    ? "已退款"
                    : ""
                }}
              </div>
              <!-- <div class="header-input"> -->
              <div v-if="order.orderStatus == '3'||order.orderStatus == '4'">
                <div class="m15">退款原因：</div>
                <div class="m15 reason">{{ refundContent }}</div>
                <div class="m15">图片说明：</div>
                <div class="m15" style="width: 57%">
                  <div
                    class="reasonPhotos"
                    v-for="item in orderRefundEnclosureList"
                  >
                    <!-- <img
                      :src="item.url"
                      alt=""
                      style="width: 100%; height: 100%"
                    /> -->
                    <el-image 
                      style="width: 100%; height: 100%"
                      :src="item.url" 
                      :preview-src-list="srcList">
                    </el-image>

                  </div>
                </div>
              </div>

              <!-- 弹窗 -->
              <el-dialog
                title="发货信息"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
              >
                <el-form :model="order" ref="form">
                  <el-form-item
                    prop="courierNumber"
                    label="快递单号"
                    style="margin-top: 15px;margin-left:3%;"
                  >
                    <el-input
                      v-model="order.courierNumber"
                      autocomplete="off"
                      style="width: 300px;"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    prop="logisticsCompany"
                    label="物流公司"
                    style="margin-top: 15px;margin-left:3%;"
                  >
                    <el-input
                      v-model="order.logisticsCompany"
                      autocomplete="off"
                      style="width: 300px"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="发货时间" prop="courierTime" style="margin-left:3%;">
                    <div class="block">
                      <el-date-picker
                        v-model="courierTime"
                        type="datetime"
                        placeholder="选择日期时间"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="submitForm"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </div>
            <!--  多功能 -->
            <!-- <OrderFunction></OrderFunction> -->

            <!-- 扩展-->
            <!-- <OrderExtends></OrderExtends> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import OrderFunction from "@/views/order/OrderFunction.vue";
import OrderExtends from "@/views/order/OrderExtends.vue";
import moment from "moment";
export default {
  components: {
    OrderFunction,
    OrderExtends,
  },
  data() {
    return {
      srcList:[],
      dialogFormVisible: false,
      tableDataItem: [],
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      courierTime:'',
      order: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        orderNumber: "",
        productCount: "",
        orderAmount: "",
        consigneeName: "",
        consigneePhone: "",
        consigneeAddress: "",
        orderStatus: "",
        courierNumber: "",
        courierTime: "",
        logisticsCompany: "",
        agentPromptCode: "",
        agentGardenName: "",
        payType: "",
        linkNumber: "",
        distributionMode: "",
        mailAmount: "",
        memberCode: "",
      },
      refundContent: "",
      orderRefundEnclosureList: [],
    };
  },
  computed: {
    chosenMoney() {
      let money = 0;
      this.tableDataItem.forEach((v) => {
        money += v.num * v.price;
      });
      return money;
    },
  },
  methods: {
    refund() {
      var getData = {};
      getData.orderNumber = this.order.orderNumber;
      getData.payType = this.order.payType;
      getData.orderAmount = this.order.orderAmount;
      this.$http.post("/order/orderRefund", getData).then((response) => {
        if (response.data.code == 200) {
          if (response.data.data.return_msg == "OK") {
            this.upDateStatus();
          }else if (response.data.data.code==10000) {
             this.upDateStatus();
          }else{
            this.$notify.success({
            title: "提示",
            message: "退款失败，请稍后再试！",
            showClose: true,
          });
          }
        }
      });
    },

    upDateStatus() {
      var sendData = {};
      sendData.id = this.order.id;
      sendData.orderStatus = "4";
      this.$http.post("/order/update", sendData).then((resp) => {
        if (resp.data.code == 200) {
         this.$notify.success({
            title: "提示",
            message: "退款成功",
            showClose: true,
          });
          this.goIndex();
        }
      });
    },

    goIndex() {
      this.$router.push({
        path: "/order",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      if (this.order.courierNumber == "") {
        this.$message.warning("请输入快递单号");
      } else if (this.order.logisticsCompany == "") {
        this.$message.warning("请输入物流公司");
      } else if (
        this.courierTime == "" ||
        this.courierTime == undefined
      ) {
        this.$message.warning("请选择发货时间");
      } else {
        var that = this;
        that.order.courierTime=that.courierTime
        that.order.orderStatus = "1";
        that.$http.post("/order/save", that.order).then((response) => {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            this.goIndex();
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http.post("/order/queryOne", { id: this.id }).then((response) => {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.tableDataItem = response.data.data.orderItemList;
            that.order = response.data.data;
            that.order.createTime=moment(response.data.data.createTime).format(
                    "yyyy-MM-DD hh:mm:ss"
                  );
            that.order.courierTime =
              response.data.data.courierTime == ""
                ? "/"
                : response.data.data.courierTime == null
                ? "/"
                : moment(response.data.data.courierTime).format(
                    "yyyy-MM-DD hh:mm:ss"
                  );
            // 退款商品详情
            if (that.order.orderStatus == '3'||that.order.orderStatus == '4') {
              that.$http
                .post("orderRefund/queryOne", {
                  orderNumber: that.order.orderNumber,
                })
                .then((res) => {
                  if (res.data.code == 200) {
                    //  console.log('退款详情',res.data.data)
                    this.refundContent = res.data.data.refundContent;
                    this.orderRefundEnclosureList =
                      res.data.data.orderRefundEnclosureList;
                      res.data.data.orderRefundEnclosureList.forEach(item => this.srcList.push(item.url))
                  }
                });
            }
          }
        });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    // console.log('收到的id',id)
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.m15 {
  margin: 15px;
}
.totalprice {
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  /* line-height: 80px; */
  width: 200px;
  height: 80px;
  margin-left: 80%;
}
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
.reason {
  width: 56%;
  height:auto;
  // background: lightpink;
  font-size: 10pt;
  border-radius: 3px;
  border: 1px solid rgb(235, 238, 245);
  padding: 3px 1%;
}
.reasonPhotos {
  width: 200px;
  height: 130px;
  // background: lightpink;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
}
</style>
